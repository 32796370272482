import { intersectionObserver } from './../helpers/intersectionObserver.js';
import { accordions } from './../components/accordions.js';

export default {
	init() {
		intersectionObserver();

		// accordions
		const accordionGroup = document.querySelectorAll('accordion-group');
		if (accordionGroup) {
			accordions();
		}
	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
